import React from 'react'
import { Link } from "gatsby";

const PriceTabs = ({ pricing }) => {
  return (
    <ul className={`w-full flex`}>
      {pricing.map((price, idx) => {
        const title = price?.heading?.title
        const icon = price?.heading?.icon

        return (
          <li key={idx} className={`cursor-pointer hover:bg-gray-200 w-full h-28 flex border-t-4 ${price.selected === true ? 'border-green-900 bg-gray-100' : 'border-green-700 bg-gray-50'}`}>
            <Link to={`/prices#${price.url}`} className="text-center w-full self-center">
              <div className="h-full w-full flex justify-center align-middle p-8">
                <i className={`${icon} text-5xl text-gray-800 font-light`}></i><h1 className="self-center font-light hidden md:block">{title}</h1>
              </div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default PriceTabs
