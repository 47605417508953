import React from 'react'
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

const PriceContent = ({ pricing }) => {
  return pricing.map((price, idx) => {
    const img = price?.image && imageUrlFor(buildImageObj(price.image))
    .url()

    return (
      <section key={idx} className={`overflow-hidden bg-white relative ${price.selected ? 'h-auto' : 'h-0'}`}>
        <div className={`${price.selected ? 'translate-x-3/3' : '-translate-x-full'} w-full top-0 left-0 transform transition-all duration-1000 ease-in-out`}>
          {price?.image?.asset?.url && (
            <div className="relative text-center h-full max-h-80 overflow-hidden">
              <img src={img} className="w-full h-auto object-contain"></img>
              <div className="z-20 border-4 w-96 h-20 align-middle justify-center items-center flex bg-gray-900 bg-opacity-50 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <p className="font-bold uppercase text-white tracking-widest">{price?.image?.heading}</p>
              </div>
            </div>
          )}
          <div className="pl-12 pr-12 pb-12 pt-6">
            {price.priceList.map((item, idx) => {
              return (
                <div key={idx} className="border-b pt-6">
                  <div className="flex justify-between">
                    <h3 className="uppercase font-semibold tracking-widest">{item.name}</h3>
                    <div className="font-semibold text-green-800">&pound;{item.price}</div>
                  </div>
                  <div className="w-3/4 pt-4 pb-6">{item.description}</div>
                </div>
              )
            })}
            <div className="flex justify-center mt-20 text-green-800 font-extralight">
              :::::
            </div>
          </div>
        </div>
      </section>
    )
  })
}

export default PriceContent
