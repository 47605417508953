import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import { Heading, Subheading } from '../components/headings'
import Layout from "../containers/layout";
import GrizzlyLook from "../components/grizzly-look";
import PriceTabs from "../components/priceTabs";
import PriceContent from "../components/priceContent";
import slugify from "slugify"

export const query = graphql`
  query PricingTemplateQuery {
    page: sanityPricing {
      heading
      subheading

      prices {
        heading {
          title
          icon
        }
        image {
          ...SanityMainImageWithText
        }
        priceList {
          name
          description
          price
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      name
      announcement {
        enabled
      }
      keywords
      openGraph {
        title
        description
        image {
          ...SanityMainImage
        }
      }
      ...NavMenu
    }
  }
`;

const PricePage = props => {
  const { data, errors, location } = props;
  const [pricing, setPricing] = useState([])

  useEffect(() => {
    const prices = data.page?.prices
    const pricing = prices.map((price, idx) => {
      const title = price?.heading?.title
      const url = slugify(title).toLocaleLowerCase()
      const selected = location.hash ? `#${url}` === location.hash : idx === 0

      return {
        ...price,
        url,
        selected
      }
    })
    setPricing(pricing)
  }, [location, data.prices])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const page = data.page
  const site = data.site
  const hasAnnouncement = site.announcement.enabled
  const pageTitle = `Prices | ${site.name}`

  return (
    <Layout nav={site.navMenu} textWhite={true}>
      <SEO
        title={pageTitle}
        bodyAttr={{
          class: "leading-normal tracking-normal text-white bg-gray-800"
        }}
      />
      <div className={`${hasAnnouncement ? 'md:pt-40' : 'md:pt-24'}`}>
        <section className="p-12 pb-20 mb-36 lg:md:p-24 text-white text-center">
          <Heading text={page.heading} />
          <Subheading text={page.subheading} />
        </section>

        <section className="bg-gray-100">
          <div className="bg-gray-100 absolute w-full h-20 transform -mt-6 -skew-y-2"></div>
          <div className="text-gray-800 relative m-auto -top-36 max-w-screen-lg z-20">
            <PriceTabs pricing={pricing} />
            <PriceContent pricing={pricing} />
          </div>
        </section>

        <GrizzlyLook />
      </div>
    </Layout>
  );
};

export default PricePage;
